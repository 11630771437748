import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "D:/Projects/Freelance/nostacknet/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "nostack-front-end-guidelines"
    }}>{`NoStack Front End Guidelines`}</h1>
    <p>{`The goal of the NoStack project is that changes to the backend will only minimally affect the front end. Therefore, it is essential that you follow certain rules for any front end code.`}</p>
    <p>{`Any NoStack app code should be checked using the ns-front CLI to ensure that these guidelines have been followed.`}</p>
    <h2 {...{
      "id": "autogenerated-nostack-code"
    }}>{`Autogenerated NoStack Code`}</h2>
    <p>{`A generated, unstyled NoStack React app contains a directory src/components with generated components. The components are each given a separate directory, grouped within unit directories. At times, this code must be regenerated. Your changes need to follow the guidelines below to be preserved even after a regeneration. If you see a problem with doing so, please contact us at `}
      <a {...{
        "href": "mailto:info@pivotate.com",
        "parentName": "p"
      }}>{`info@pivotate.com`}</a>
      {` with any issues or requests.`}</p>
    <h2 {...{
      "id": "making-custom-changes"
    }}>{`Making Custom Changes`}</h2>
    <p>{`The following types of changes are permitted:`}</p>
    <h2 {...{
      "id": "custom-added-code"
    }}>{`Custom Added Code`}</h2>
    <p>{`Replacement Code`}</p>
    <p>{`Custom New Components or Functions`}</p>
    <p>{`Removed Code`}</p>
    <p>{`Custom State`}</p>
    <p>{`Each type contains a section of explanation below.`}</p>
    <p>{`Custom Added Code
You may insert any code that your app requires within designated locations. A custom code location is currently specified with commented out delimiters at the beginning and end, as follows:`}</p>
    <p>{`That code should be replaced completely after a generation, and is ideal. If you feel a need to add custom code somewhere else in a file, contact `}
      <a {...{
        "href": "mailto:info@pivotate.com",
        "parentName": "p"
      }}>{`info@pivotate.com`}</a>
      {` and specify the file and location, showing the code that you want to add. We are actively adding the custom locations currently.`}</p>
    <p>{`Please understand that code outside of the custom locations will not persevere through a regeneration of the code.`}</p>
    <p>{`Replacement Code
The files contain several sections which can be replaced by your own code. They are currently designated as follows:`}</p>
    <pre><code {...{
        "parentName": "pre"
      }}>{`// ns__start_section <location name> … replaceable here… // ns__end_section <location name>
`}</code></pre>
    <p>{`Please note that if anything on the backend that is relevant to replaced code changes, then the code might be broken. So it’s always preferred to add custom code rather than replacement code. But, often replacement code will be necessary somewhere in an app. To replace a section, change the commented out delimiters as follows, and then your changes to the code within will persist:`}</p>
    <p>{`Custom New Components or Functions
There is a designated folder, src/custom, for your own code. You can do whatever you’d like there. Just realize that the more you change from generated code the more fragile the changes will be, so the general rule is that it’s better to stick to the generated components if you can.`}</p>
    <p>{`Removed Code
So far, only import statements can be removed. To remove an import statement, such as`}</p>
    <pre><code {...{
        "parentName": "pre"
      }}>{`// import styled …
`}</code></pre>
    <p>{`simply place “// ns_`}
      <em {...{
        "parentName": "p"
      }}>{`remove`}</em>
      {`” in front of it, e.g.`}</p>
    <pre><code {...{
        "parentName": "pre"
      }}>{`// ns__remove_import styled …
`}</code></pre>
    <p><strong {...{
        "parentName": "p"
      }}>{`Custom State`}</strong>
      {`
Each unit contains a context, which contains a “custom” object. You can modify that custom object as needed for your app in all of the designated custom code areas as specified above.`}</p>

  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      